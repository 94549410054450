import React from "react"
import PropTypes from "prop-types"

export function FloatingBanner({
  color,
  message,
  header,
  acceptText,
  denyText,
  onAccept,
  onDeny,
}) {
  return (
    <div className="columns" id="cookies-banner" style={{ position:'fixed',bottom:'0px',margin:'0px',
    borderTop:'1px solid #23C495',
    backgroundColor:`${color}`,left:'0px',width:'100%', zIndex: "2000",
    padding:'2em 5em' }}>
        <div className="column is-7" id="cookies-text">
        <div className={`title is-size-4`} style={{color:'whitesmoke'}}
        dangerouslySetInnerHTML={{ __html: header }} />
          <div className="ml-3 font-medium text-white">
            <span style={{color:'whitesmoke'}}
            dangerouslySetInnerHTML={{ __html: message}} />
          </div>
          <br />
        </div>
        <div className="column">
        <div style={{alignItems:'center',display:'flex',flexWrap:'wrap',
        justifyContent:'center',margin:'auto',height:'100%'
        }}>
              {acceptText !== undefined && (
                <div
                style={{textAlign:'center',padding:'10px'}}
                >
                    {onAccept !== undefined && (
                      <button
                        className={`button-green is-small`}
                        style={{minWidth:'200px'}}
                        onClick={onAccept}
                        dangerouslySetInnerHTML={{ __html: acceptText}} />
                    )}
                </div>
              )}
              {denyText !== undefined && (
                <div
                style={{textAlign:'center',padding:'10px'}}
                >
                  <button
                    className={`button-green is-small`}
                    style={{minWidth:'200px'}}
                    onClick={onDeny}
                    dangerouslySetInnerHTML={{ __html: denyText}} />
                </div>
              )}
        </div>
      </div>
    </div >
  )
}

FloatingBanner.propTypes = {
  color: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  acceptText: PropTypes.string.isRequired,
  denyText: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDeny: PropTypes.func.isRequired,
}

FloatingBanner.defaultProps = {
  color: "#111111",
}
