import React, { useEffect, useState, useMemo, useCallback } from "react"
import { FloatingBanner } from "./FloatingBanner"
import Cookies from "universal-cookie"
import PropTypes from "prop-types"

function ConsentForm({ color }) {
    const [decisionMade, setDecisionMade] = useState(true) // start with true to avoid flashing
    const cookies = useMemo(() => new Cookies(null, { path: '/' }), []);

    function gtag() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(arguments);
    }

    const sendConsent = useCallback((consent) => {
        gtag('consent', 'default', consent);
    }, []);

    useEffect(() => {
        if (cookies.get("cookie_v2") !== undefined) {
            setDecisionMade(true)
        } else {
            setDecisionMade(false)
        }
    }, [cookies, setDecisionMade, sendConsent])

    const handleDecision = (outcome) => {
        const consent = {
            'ad_storage': outcome,
            'analytics_storage': outcome,
            'ad_user_data': outcome,
            'ad_personalization': outcome,
            'functionality_storage': outcome,
            'personalization_storage': outcome,
            'security_storage': outcome,
        }

        cookies.set("cookie_v2", consent, {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            path: "/",
            domain: "artopen.co",
        })
        //console.log(cookies.cookies.cookie_v2)
        sendConsent(consent)
        setDecisionMade(true)
    }

    return (
        decisionMade ? (
            <>
            <button role="button" aria-label="cookie-consent" className="button-green"
            onClick={
                () => {
                    setDecisionMade(false);
                }
            }
            style={{position:'fixed',bottom:'0',left:'0',fontSize:'15px'}}>
            <span role="img" aria-label="cookie-emoji">🍪</span>
            </button>
            </>
        ) : (
            <FloatingBanner
                color={color}
                header='<span role="img" aria-label="cookie-emoji">🍪</span>&nbsp; We use cookies'
                message={`We would like to inform you that <b style="color:#23C495">Art Open</b> uses cookies
                 for the proper operation of our website, as well as for analytical and advertising purposes.
                 <br><br>
                 You can learn more about how we use cookies in our <a class='inline-link' href='/privacy-policy/#cookies'>Privacy Policy</a>.`}
                acceptText="Accept<br> all"
                denyText="Deny<br> all"
                onAccept={
                    () => {
                        handleDecision("granted")
                    }
                }
                onDeny={
                    () => {
                        handleDecision("denied")
                    }
                } />
        )
    )
}

ConsentForm.propTypes = {
    color: PropTypes.string.isRequired,
}

ConsentForm.defaultProps = {
    color: "#111111",
}

export default ConsentForm;
