import React, { Suspense, useState } from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import SearchBox from '../SearchBox'
import {NewsletterForm} from '../../components/NewsletterForm'

//const {NewsletterForm} = React.lazy(() => import('../../components/NewsletterForm'))

class NavBar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

   toggleNavBar = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  componentDidMount(){
    // Create a media condition that targets viewports at least 1208px wide
    const mediaQuery = window.matchMedia('(max-width: 1023px)');
    mediaQuery.addListener(handleTabletChange);
    handleTabletChange(mediaQuery);
    // Register event listener
    //mediaQuery.addListener(handleTabletChange);
    //handleTabletChange(mediaQuery);
  }

render() {
  const { data } = this.props
  const { edges: posts } = data.allMarkdownRemark

  return (


        <nav className='navbar is-fixed-top' id='bignav' aria-label='main navigation'>
          <div className='navbar-brand'>
            <Link  to='/' className='navbar-item' style={{marginLeft:'10px'}}>
              <img id="logomain" width="100px" style={{transform:'scale(1.3)'}} src='/img/ao_ukraina.svg' alt="Art Open advertising agency" />
            </Link>
            <button
              className={`button navbar-burger ${this.state.navBarActiveClass}`}
              data-target='navMenu'
              aria-label="Navbar-mobile"
              onClick={() => this.toggleNavBar()}
            >
              <span />
              <span />
              <span />
            </button>
          </div>
          <div className={`navbar-menu ${this.state.navBarActiveClass}`} id='navMenu'>

            <div className='navbar-end'>

            <Link className='navbar-item'  to='/about-us/'>
              ABOUT US
            </Link>

            <Link className='navbar-item'  to='/job/'>
              JOB
            </Link>

            <Link id="off" className="navbar-item" to="/offer/">
              OFFER  <span id="subtog" style={{marginLeft:'10px',cursor:'pointer'}}><img id="down" src="/img/angle-down.svg" alt="arrow" width="15px" /> </span>
            </Link>


            <nav className="subMenu" id="sub">
            {/*<Link className='submenu-item' to='/offer/covid-19/'>COVID-19</Link>*/}
              <Link className='submenu-item' to='/offer/ecology/'>ECOLOGY</Link>


              <span className="submenu-item has-sub">
              <Link  to="/offer/graphic-design/">
              GRAPHIC DESIGN</Link>
              {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Graphic design').length !== 0 ? <span className="toggle" style={{marginLeft:'10px',cursor:'pointer'}}><img className="dicon" src="/img/angle-down.svg" alt="arrow" width="10px" /> </span> : <span style={{display:'none'}} className="dicon toggle"></span> }
              </span>

              <div className="subsub">
                {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Graphic design')
                  .map(({ node: post }) => (
                    <Link key={post.id} className='submenu-item' to={post.fields.slug} >
                      {post.frontmatter.title}
                    </Link>
                ))}
              </div>

              <span className="submenu-item has-sub">
              <Link  to="/offer/web-pages/">
                WEBSITES</Link>
              {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Web pages').length !== 0 ? <span className="toggle" style={{marginLeft:'10px',cursor:'pointer'}}><img className="dicon" src="/img/angle-down.svg" alt="arrow" width="10px" /> </span> : <span style={{display:'none'}} className="dicon toggle"></span> }
              </span>

              <div className="subsub">
                {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Web pages')
                  .map(({ node: post }) => (
                    <Link key={post.id} className='submenu-item' to={post.fields.slug} >
                      {post.frontmatter.title}
                    </Link>
                ))}
              </div>


              <span className="submenu-item has-sub">
              <Link  to="/offer/branding/">
                BRANDING</Link>
              {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Branding').length !== 0 ? <span className="toggle" style={{marginLeft:'10px',cursor:'pointer'}}><img className="dicon" src="/img/angle-down.svg" alt="arrow" width="10px" /> </span> : <span style={{display:'none'}} className="dicon toggle"></span> }
              </span>

              <div className="subsub">
                {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Branding')
                  .map(({ node: post }) => (
                    <Link key={post.id} className='submenu-item' to={post.fields.slug} >
                      {post.frontmatter.title}
                    </Link>
                ))}
              </div>

              <span className="submenu-item has-sub">
              <Link  to="/offer/book-calendars/">
                BOOK CALENDARS</Link>
              {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Kalendarze książkowe').length !== 0 ? <span className="toggle" style={{marginLeft:'10px',cursor:'pointer'}}><img className="dicon" src="/img/angle-down.svg" alt="arrow" width="10px" /> </span> : <span style={{display:'none'}} className="dicon toggle"></span> }
              </span>

              <div className="subsub">
                {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Kalendarze książkowe')
                  .map(({ node: post }) => (
                    <Link key={post.id} className='submenu-item' to={post.fields.slug} >
                      {post.frontmatter.title}
                    </Link>
                ))}
              </div>

              <span className="submenu-item has-sub">
              <Link  to="/offer/company-calendars/">
                COMPANY CALENDARS</Link>
              {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Kalendarze firmowe').length !== 0 ? <span className="toggle" style={{marginLeft:'10px',cursor:'pointer'}}><img className="dicon" src="/img/angle-down.svg" alt="arrow" width="10px" /> </span> : <span style={{display:'none'}} className="dicon toggle"></span> }
              </span>

              <div className="subsub">
                {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Kalendarze firmowe')
                  .map(({ node: post }) => (
                    <Link key={post.id} className='submenu-item' to={post.fields.slug} >
                      {post.frontmatter.title}
                    </Link>
                ))}
              </div>

              <span className="submenu-item has-sub">
              <Link  to="/offer/gadgets/">
                PROMOTIONAL GADGETS</Link>
              {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Gadżety').length !== 0 ? <span className="toggle" style={{marginLeft:'10px',cursor:'pointer'}}><img className="dicon" src="/img/angle-down.svg" alt="arrow" width="10px" /> </span> : <span style={{display:'none'}} className="dicon toggle"></span> }
              </span>

              <div className="subsub">
                {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Gadżety')
                  .map(({ node: post }) => (
                    <Link key={post.id} className='submenu-item' to={post.fields.slug} >
                      {post.frontmatter.title}
                    </Link>
                ))}
              </div>


              <span className="submenu-item has-sub">
              <Link  to="/offer/printouts/">
              PROMOTIONAL PRINTS</Link>
              {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Poligrafia').length !== 0 ? <span className="toggle" style={{marginLeft:'10px',cursor:'pointer'}}><img className="dicon" src="/img/angle-down.svg" alt="arrow" width="10px" /> </span> : <span style={{display:'none'}} className="dicon toggle"></span> }
              </span>

              <div className="subsub">
                {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Poligrafia')
                  .map(({ node: post }) => (
                    <Link key={post.id} className='submenu-item' to={post.fields.slug} >
                      {post.frontmatter.title}
                    </Link>
                ))}
              </div>

              <span className="submenu-item has-sub">
              <Link  to="/offer/outdoor-advertising/">
              OUTDOOR ADVERTISEMENTS</Link>
              {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Reklama zewnętrzna').length !== 0 ? <span className="toggle" style={{marginLeft:'10px',cursor:'pointer'}}><img className="dicon" src="/img/angle-down.svg" alt="arrow" width="10px" /> </span> : <span style={{display:'none'}} className="dicon toggle"></span> }
              </span>

              <div className="subsub">
                {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Reklama zewnętrzna')
                  .map(({ node: post }) => (
                    <Link key={post.id} className='submenu-item' to={post.fields.slug} >
                      {post.frontmatter.title}
                    </Link>
                ))}
              </div>


              <span className="submenu-item has-sub">
              <Link  to="/offer/photography/">
              PRODUCT PHOTOGRAPHY</Link>
              {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Fotografia').length !== 0 ? <span className="toggle" style={{marginLeft:'10px',cursor:'pointer'}}><img className="dicon" src="/img/angle-down.svg" alt="arrow" width="10px" /> </span> : <span style={{display:'none'}} className="dicon toggle"></span> }
              </span>

              <div className="subsub">
                {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Fotografia')
                  .map(({ node: post }) => (
                    <Link key={post.id} className='submenu-item' to={post.fields.slug} >
                      {post.frontmatter.title}
                    </Link>
                ))}
              </div>


              <span className="submenu-item has-sub">
              <Link  to="/offer/exhibition/">
              EXHIBITION SYSTEMS</Link>
              {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Systemy wystawiennicze').length !== 0 ? <span className="toggle" style={{marginLeft:'10px',cursor:'pointer'}}><img className="dicon" src="/img/angle-down.svg" alt="arrow" width="10px" /> </span> : <span style={{display:'none'}} className="dicon toggle"></span> }
              </span>

              <div className="subsub">
                {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Systemy wystawiennicze')
                  .map(({ node: post }) => (
                    <Link key={post.id} className='submenu-item' to={post.fields.slug} >
                      {post.frontmatter.title}
                    </Link>
                ))}
              </div>



              <span className="submenu-item has-sub">
              <Link  to="/offer/filming/">
              COMMERCIALS</Link>
              {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Filmowanie').length !== 0 ? <span className="toggle" style={{marginLeft:'10px',cursor:'pointer'}}><img className="dicon" src="/img/angle-down.svg" alt="arrow" width="10px" /> </span> : <span style={{display:'none'}} className="dicon toggle"></span> }
              </span>

              <div className="subsub">
                {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Filmowanie')
                  .map(({ node: post }) => (
                    <Link key={post.id} className='submenu-item' to={post.fields.slug} >
                      {post.frontmatter.title}
                    </Link>
                ))}
              </div>


              <span className="submenu-item has-sub">
              <Link  to="/offer/packaging/">
              PACKAGES</Link>
              {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Opakowania').length !== 0 ? <span className="toggle" style={{marginLeft:'10px',cursor:'pointer'}}><img className="dicon" src="/img/angle-down.svg" alt="arrow" width="10px" /> </span> : <span style={{display:'none'}} className="dicon toggle"></span> }
              </span>

              <div className="subsub">
                {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'Opakowania')
                  .map(({ node: post }) => (
                    <Link key={post.id} className='submenu-item' to={post.fields.slug} >
                      {post.frontmatter.title}
                    </Link>
                ))}
              </div>


              <span className="submenu-item has-sub">
              <Link  to="/offer/vr/">
              VIRTUAL REALITY</Link>
              {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'VR / AR').length !== 0 ? <span className="toggle" style={{marginLeft:'10px',cursor:'pointer'}}><img className="dicon" src="/img/angle-down.svg" alt="arrow" width="10px" /> </span> : <span style={{display:'none'}} className="dicon toggle"></span> }
              </span>

              <div className="subsub">
                {posts &&
                  posts
                  .filter(post => post.node.frontmatter.subcategory === 'VR / AR')
                  .map(({ node: post }) => (
                    <Link key={post.id} className='submenu-item' to={post.fields.slug} >
                      {post.frontmatter.title}
                    </Link>
                ))}
              </div>
            </nav>

            <Link className='navbar-item'  to='/portfolio/'>
              PORTFOLIO
            </Link>


            <Link to="/blog/" id="blog" className="navbar-item">
              BLOG <span id="subtog2" style={{marginLeft:'10px',cursor:'pointer'}}><img id="down2" src="/img/angle-down.svg" alt="arrow" width="15px" /> </span>
            </Link>

            <nav className="subMenu" id="blogsub">
            {posts &&
              posts
              .filter(post => post.node.frontmatter.templateKey === 'article-page')
              .slice(0,15)
              .map(({ node: post }) => (
                <Link className='submenu-item' key={post.id} to={post.fields.slug} >
                  {post.frontmatter.title}
                </Link>
            ))}

            </nav>

            <Link className='navbar-item'  to='/catalogs/'>
            CATALOGS
            </Link>


            <Link className='navbar-item'  to='/contact/'>
              CONTACT
            </Link>
            </div>

            <div className='navbar-item'>
              <div className='field is-grouped'>
                <p className='control'>
                  <a
                    className='button-green tooltip'
                    href="/quote-art-open/"
                    target="_blank"
                    >
                    Get a quote<span className="tooltiptextdown">After clicking, you will be redirected to the subpage with forms containing services from our offer. If you are interested, please answer the questions and send the form.</span>
                  </a>
                </p>

                <p className='control'>
                  <a
                    href="/calendar-creator-online/"
                    className='button-green tooltip'
                    target="_blank"
                    >
                    Calendar wizard<span className="tooltiptextdown">Let your imagination run wild and design your own calendar now.</span>
                  </a>
                </p>

              </div>

            </div>
            <div className='navbar-item search' style={{marginLeft:'-25px'}}>
            <SearchBox searchIndex={data.siteSearchIndex.index} />



            </div>

            <div className='navbar-item langs'>
              <div className='field is-grouped'>
              <p className='control'>
                <a
                  className='button-green'
                  style={{display:'flex'}}
                  href='https://artopen.pl'>
                  <img src="/img/polish.png" alt="polish" style={{filter:'none'}} width="20px" />&nbsp;&nbsp;
                  PL
                </a>
              </p>
              </div>
            </div>





          </div>

          <div id='newsletter'
          style={{display:'none', transition:'opacity 1s',opacity:'0',position:'fixed',top:'0px',left:'0px',height:'100%',width:'100%',backgroundColor:'rgba(255,255,255,0.85)',zIndex:'20000',overflow:'scroll'}}>
            <div style={{marginLeft:'5%',marginRight:'5%',paddingTop:'50px'}}>


            <NewsletterForm />


            </div>
            </div>

        </nav>



  )
}
}






function handleTabletChange(e) {
  // Check if the media query is true
  if (e.matches) {
    console.log(e);
    document.getElementById('off').removeEventListener("mouseover",loadSubMenu);
    document.getElementById('off').removeEventListener("mouseleave",hideSubMenu);
    document.getElementById('off').removeEventListener("focus",hideSubMenu);

    document.getElementById('blog').removeEventListener("mouseover",loadSubMenu2);
    document.getElementById('blog').removeEventListener("mouseleave",hideSubMenu2);
    document.getElementById('blog').removeEventListener("focus",hideSubMenu2);

    document.getElementById('sub').removeEventListener("mouseleave",hideSubMenu);
    document.getElementById('sub').removeEventListener("mouseover",loadSubMenu);

    document.getElementById('blogsub').removeEventListener("mouseleave",hideSubMenu2);
    document.getElementById('blogsub').removeEventListener("mouseover",loadSubMenu2);

    document.getElementById('bignav').removeEventListener("mouseleave",hideSubMenu);

    document.getElementById('subtog').removeEventListener("mouseover",loadSubMenu);
    document.getElementById('subtog2').removeEventListener("mouseover",loadSubMenu2);

    document.getElementById('subtog').addEventListener("click",showSubMenu);
    document.getElementById('subtog2').addEventListener("click",showSubMenu2);

    for (let i=0;i<document.getElementsByClassName('subsub').length;i++){
      document.getElementsByClassName('subsub')[i].classList.remove('subadd');
    }

    for (let i=0;i<document.getElementsByClassName('toggle').length;i++){
      document.getElementsByClassName('toggle')[i].addEventListener("click", function fn(){showSubSub(i)});
    }




  } else {
  //console.log(e);

  document.getElementById('off').addEventListener("mouseover",loadSubMenu);
  document.getElementById('off').addEventListener("mouseleave",hideSubMenu);
  document.getElementById('off').addEventListener("focus",hideSubMenu);

  document.getElementById('blog').addEventListener("mouseover",loadSubMenu2);
  document.getElementById('blog').addEventListener("mouseleave",hideSubMenu2);
  document.getElementById('blog').addEventListener("focus",hideSubMenu2);

  document.getElementById('sub').addEventListener("mouseleave",hideSubMenu);
  document.getElementById('sub').addEventListener("mouseover",loadSubMenu);

  document.getElementById('blogsub').addEventListener("mouseleave",hideSubMenu2);
  document.getElementById('blogsub').addEventListener("mouseover",loadSubMenu2);

  document.getElementById('subtog').addEventListener("mouseover",loadSubMenu);
  document.getElementById('subtog2').addEventListener("mouseover",loadSubMenu2);

  document.getElementById('bignav').addEventListener("mouseleave",hideSubMenu);
  document.getElementById('subtog').removeEventListener("click",showSubMenu);
  document.getElementById('subtog2').removeEventListener("click",showSubMenu2);

  for (let i=0;i<document.getElementsByClassName('subsub').length;i++){
    document.getElementsByClassName('subsub')[i].classList.add('subadd');
  }





 for (let i=0;i<document.getElementsByClassName('has-sub').length;i++){
   if(document.getElementsByClassName('subsub')[i].childNodes.length > 0){
    //console.log(i);
    // console.log(document.getElementsByClassName('subsub')[i].childNodes.length);
     document.getElementsByClassName('has-sub')[i].addEventListener("mouseover",function(){showSubSubDesktop(i)});
     document.getElementsByClassName('has-sub')[i].addEventListener("mouseleave",function(){hideSubSubDesktop(i)});
     document.getElementsByClassName('subsub')[i].addEventListener("mouseover",function(){showSubSubDesktop(i)});
     document.getElementsByClassName('subsub')[i].addEventListener("mouseleave",function(){hideSubSubDesktop(i)});
   }


    //
  }
  //console.log('added');
}
}



const showSubSub = (x) => {
if(document.getElementsByClassName('subsub')[x].style.display == 'none'){
  document.getElementsByClassName('subsub')[x].style.display = 'block';
  document.getElementsByClassName('dicon')[x].style.transform = 'rotate(180deg)';
} else {
  document.getElementsByClassName('subsub')[x].style.display = 'none';
  document.getElementsByClassName('dicon')[x].style.transform = 'rotate(0deg)';
}
}

const showSubSubDesktop = (x) => {
  document.getElementsByClassName('subsub')[x].style.display = 'block';
  if(window.matchMedia('(max-width: 1023px)').matches==true){
    document.getElementsByClassName('dicon')[x].style.transform = 'rotate(180deg)';
  } else {
  document.getElementsByClassName('dicon')[x].style.transform = 'rotate(-90deg)';
}
}

const hideSubSubDesktop = (x) => {
  document.getElementsByClassName('subsub')[x].style.display = 'none';
  document.getElementsByClassName('dicon')[x].style.transform = 'rotate(0deg)';
}














const showSubMenu = () => {
if (document.getElementsByClassName('subMenu')[0] !== null){
if(document.getElementsByClassName('subMenu')[0].style.display == 'none'){
  document.getElementsByClassName('subMenu')[0].style.display = 'block';
  document.getElementById('down').style.transform = 'rotate(180deg)';
} else {
  document.getElementsByClassName('subMenu')[0].style.display = 'none';
  document.getElementById('down').style.transform = 'rotate(0deg)';
}

}
}

const showSubMenu2 = () => {
if (document.getElementsByClassName('subMenu')[1] !== null){
if(document.getElementsByClassName('subMenu')[1].style.display == 'none'){
  document.getElementsByClassName('subMenu')[1].style.display = 'block';
  document.getElementById('down2').style.transform = 'rotate(180deg)';
} else {
  document.getElementsByClassName('subMenu')[1].style.display = 'none';
  document.getElementById('down2').style.transform = 'rotate(0deg)';
}

}
}





const loadSubMenu = () => {
  if (document.getElementsByClassName('subMenu')[0] !== null){
    document.getElementsByClassName('subMenu')[0].style.display = 'block';
    document.getElementById('down').style.transform = 'rotate(180deg)';
}
}
const hideSubMenu = () => {
  if (document.getElementsByClassName('subMenu')[0] !== null){
  document.getElementsByClassName('subMenu')[0].style.display = 'none';
  document.getElementById('down').style.transform = 'rotate(0deg)';
}
}

const loadSubMenu2 = () => {
  if (document.getElementsByClassName('subMenu')[1] !== null){
    document.getElementsByClassName('subMenu')[1].style.display = 'block';
    document.getElementById('down2').style.transform = 'rotate(180deg)';
}
}
const hideSubMenu2 = () => {
  if (document.getElementsByClassName('subMenu')[1] !== null){
  document.getElementsByClassName('subMenu')[1].style.display = 'none';
  document.getElementById('down2').style.transform = 'rotate(0deg)';
}
}


export default () => (
  <StaticQuery
    query={graphql`
      query navbarQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                subcategory
              }
            }
          }
        }
        siteSearchIndex {
            index
        }
      }
    `}
    render={(data, count) => <NavBar data={data} count={count} />}
  />
)
