import loadable from '@loadable/component'
import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import '../../assets/sass/styles.sass'
import config from '../../../config'
import NavBar from '../NavBar'
import ConsentForm from '../Cookies/ConsentForm'
// import CookieConsent from 'react-cookie-consent'

const Footer = loadable(() => import('../Footer'))

const Layout = (props) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: `en-en`,
          class: `has-navbar-fixed-top`,
        }}

        title={config.siteTitle}
        meta={[
          {
            name: `description`,
            content: config.siteDescription,
          },
          {
            name: `viewport`,
            content: `width=device-width, initial-scale=1`,
          },
          {
            name: `msapplication-TileColor`,
            content: config.themeColor,
          },
          {
            name: `theme-color`,
            content: config.themeColor,
          },
        ]}
        link={[
          {
            rel: `apple-touch-icon`,
            href: `/icons/apple-touch-icon.png`,
            sizes: `180x180`,
          },
          {
            rel: `icon`,
            type: `image/png`,
            href: `/favicon-32x32.png`,
            sizes: `32x32`,
          },
          {
            rel: `icon`,
            type: `image/png`,
            href: `/favicon-16x16.png`,
            sizes: `16x16`,
          },
          {
            rel: `mask-icon`,
            href: `/icons/safari-pinned-tab.svg`,
            color: config.themeColor,
          },
        ]}
        link={[
          {
            rel: `preconnect`,
            href: `https://fonts.gstatic.com`,
          },
          {
            rel: `stylesheet`,
            href: `https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap`,
          },

        ]}
      >
      </Helmet>
      <NavBar />
      <>{props.children}</>



      <Footer copyright={config.copyright} />
      {/* Consent mode v2 IMPLEMENTATION OF GDPR COOKIE NOTICE */}
      <ConsentForm>
      </ConsentForm>
    </>
  )
}



export default Layout
