import React from 'react'
import { navigate } from 'gatsby-link'

const API_KEY = process.env.REACT_APP_MG_API_KEY
const DOMAIN = process.env.REACT_APP_MG_DOMAIN

const formData = require('form-data');
const Mailgun = require('mailgun.js');
const mailgun = new Mailgun(formData);
const client = mailgun.client({username: 'api', key: API_KEY});

function encode(data) {
  const formData = new FormData()


  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData

}


const newsDestroy = () => {
  let i = 0;
  console.log('destroy modal');

  document.getElementById('newsletter').style.transition = 'opacity 1s';
  document.getElementById('newsletter').style.opacity = '0';
  setTimeout(function(){document.getElementById('newsletter').style.display = 'none';
  },500);
}



class NewsletterForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] })
  }


  handleSubmit = e => {

        e.preventDefault()
        const form = e.target;

        fetch('/', {
          method: 'POST',
          body: formdata,
        })
          .then(() => {

            const messageData = {
              from: 'Art Open <bok@artopen.pl>',
              to: document.getElementById('adres emailXV').value,
              subject: 'Thank you for sending your inquiry!',
              html: `
    <div style="background-color: #23c495; text-align: center">
    <a target="_blank" rel="noopener noreferrer" href="https://artopen.co/">
      <img
        src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/logo-mail.png?v=1651011958996"
        width="300px"
    /></a>
    </div>

    <div
    style="
      background-color: #23c495;
      display: flex;
      align-items: center;
      justify-content: center;
    "
    >
    <div style="display: block; text-align: left; color: white; padding: 20px">
      <h1 style="font-size: 1.5em">Thank You!</h1>
      <h2
        style="
          border-radius: 5px;
          border-bottom: 15px solid #fcc515;
          font-size: 2em;
          max-width: 80%;
        "
      >
      For Sending a Message from Art Open
      </h2>
      <sub style="margin-bottom: 20px"
        >Our team <strong>will contact you shortly.</strong><br />
        On business days, <strong>we usually complete quotes within 24 hours</strong>.</sub
      >
    </div>
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://artopen.co/contact/"
        ><img src="https://artopen.co/img/newsletter.svg" width="100%"
      /></a>
    </div>
    </div>
    <br />
    <h3 style="font-size: 2em; text-align: center; margin-top: 30px">
    Get to know us better:
    <br />
    <sub style="font-size: 0.5em"
      >Find out
      <a
        target="_blank"
        style="color: #23c495"
        rel="noopener noreferrer"
        href="https://artopen.co/about-us/"
        >who we are</a
      >.</sub
    >
    </h3>

    <div style="display: flex; justify-content: center">
    <a
      target="_blank"
      style="color: #23c495"
      rel="noopener noreferrer"
      href="https://artopen.co/offer/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l4.png?v=1651011237576"
          width="100%"
        /><br />
        <p style="text-align: center">OFFER</p>
      </div></a
    >
    <a
      target="_blank"
      style="color: #23c495"
      rel="noopener noreferrer"
      href="https://artopen.co/blog/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l3.png?v=1651011237576"
          width="100%"
        /><br />
        <p style="text-align: center">BLOG</p>
      </div></a
    >
    <a
      target="_blank"
      style="color: #23c495"
      rel="noopener noreferrer"
      href="https://artopen.co/portfolio/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l2.png?v=1651011237791"
          width="100%"
        /><br />
        <p style="text-align: center">PORTFOLIO</p>
      </div></a
    >
    <a
      target="_blank"
      style="color: #23c495"
      rel="noopener noreferrer"
      href="https://artopen.co/catalogs/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l1.png?v=1651011237864"
          width="100%"
        /><br />
        <p style="text-align: center">CATALOGS</p>
      </div></a
    >
    </div>
    <br /><br />
    <h3 style="text-align: center; font-size: 2em">
    Join the action:<br />
    <sub style="font-size: 0.5em"
      >Check out
      <a
        target="_blank"
        style="color: #23c495"
        rel="noopener noreferrer"
        href="https://artopen.co/blog/cleaning-action-open-for-environment-v-2/"
        >action footage in 2021</a
      >.</sub
    >
    </h3>
    <br /><br />
    <div style="display: block; text-align: center">
    <div>
      <img
        src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/akcja-sprzatanie-3.png?v=1651239639520"
        width="100%"
        style="min-width: 200px; max-width: 500px"
      />
    </div>
    <div
      style="
        display: block;
        text-align: center;
        padding-left: 0px;
        color: #23c495;
      "
    >
      <h1 style="font-size: 1.5em">
      Be EKO with us and join the Cleanup Action vol.III
      </h1>
      <p style="max-width:90%;margin-left:5%;">
      Being aware of the seriousness of the problem of littering, while being an agency with a strong pro-ecological profile at the same time, we decided to take matters into our own hands and for the third time we are organizing a local cleaning action. 
        <br /><br /><br />
        <a
          style="
            color: #23c495;
            border-radius: 15px;
            padding: 10px 20px;
            border: 1px solid #23c495;
          "
          target="_blank"
          rel="noopener noreferrer"
          href="https://artopen.co/akcja-sprzatanie-vol-3/"
          >join us</a
        >
      </p>
    </div>
    </div>
    <br /><br />
    <h3 style="text-align: center; font-size: 2em">
    Check out the blog:<br />
    <sub style="font-size: 0.5em"
      >Read
      <a
        target="_blank"
        style="color: #23c495"
        rel="noopener noreferrer"
        href="https://artopen.co/blog/"
        >our articles</a
      >.</sub
    >
    </h3>
    <div
    style="
      background-color: #23c495;
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
    >
    <div style="display: block; text-align: left; padding: 20px; color: white">
      <h1 style="font-size: 2em">How to create a website?</h1>
      <p>
      What does web development look like and what does it consist of? 
      Nowadays, a company website is a must have no matter the company’s profile. 
      However, considering the competition online, simply having a website may not be enough.
        <br /><br /><br />
        <a
          style="
            color: white;
            border-radius: 15px;
            padding: 10px 20px;
            border: 1px solid white;
          "
          target="_blank"
          rel="noopener noreferrer"
          href="https://artopen.co/blog/jak-stworzyc-strone-internetowa/"
          >read more</a
        >
      </p>
    </div>
    <div>
      <img
        src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/jak.png?v=1651241484223"
        width="100%"
      />
    </div>
    </div>
    <br /><br /><br />
    <div style="display: flex; justify-content: center">
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://facebook.com/artopenstudioreklamy/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/fb.png?v=1651013201802"
          width="30px"
        /></div></a
    >&nbsp;&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://twitter.com/AReklamy/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/t.png?v=1651013201802"
          width="30px"
        /></div></a
    >&nbsp;&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.youtube.com/channel/UCR45VIn9iEmnQo7JU_FL2uQ/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/yt.png?v=1651013201802"
          width="30px"
        /></div></a
    >&nbsp;&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.linkedin.com/company/art-open-studio-reklamy/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l.png?v=1651013202010"
          width="30px"
        /></div></a
    >&nbsp;&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.behance.net/ArtOpenStudioReklamy/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/b.png?v=1651013202080"
          width="30px"
        /></div></a
    >&nbsp;&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.instagram.com/artopen.pl/"
      ><div>
        <img
          src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/i.png?v=1651013202263"
          width="30px"
        /></div></a
    >&nbsp;&nbsp;
    </div>

    <p style="text-align: center; font-size: 10px; margin-top: 10px">
    We hope you enjoy our content. If you don't
    <a
      target="_blank"
      style="color: #23c495"
      rel="noopener noreferrer"
      href="https://artopen.co/contact/"
      >here you can withdraw your subscriptions.</a
    >
    </p>
    <br /><br />

    `
            };
            console.log(client)

            client.messages.create(DOMAIN, messageData)
             .then((res) => {
               console.log(res);
             })
             .catch((err) => {
               console.error(err);
             });


            navigate('/contact/sukces/')
          }).catch(error => alert(error))
      }

  render() {
    return (

      <form
      name="Newsletter"
      id = "newsletter"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={this.handleSubmit}
      style={{marginLeft:'auto',marginRight:'auto',minHeight:'450px',maxWidth:'600px',backgroundColor:'#23C495',borderRadius:'25px',padding:'10px',paddingTop:'35px'}}>
      <a className='button-white' style={{fontSize:'20px'}} onMouseOver={newsDestroy} >&nbsp;x&nbsp;</a>


      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="newsletter" />
      <div hidden>
        <label>
          Don’t fill this out:{' '}
          <input name="bot-field"
          onChange={this.handleChange}
          />
        </label>
      </div>
      <div style={{marginLeft:'auto',marginRight:'auto',textAlign:'center'}}>
      <br /><br />
      <img loading="lazy" src='/img/newsletter.svg' style={{width:'33%',marginTop:'-12%',marginBottom:'-30px'}} alt='newsletter' />
      </div>
      <div style={{textAlign:'center',marginLeft:'30px',marginRight:'30px'}}>
      <br />
      <br />
      <p className='title stays-white'>Sign up for the newsletter! </p>
      <p className='subtitle stays-white'> News, promotions and special offers.</p>


      <input className='field input' required={true} onChange={this.handleChange} name='adres email' id="adres emailXV" type='email' placeholder='Your email address'>
      </input>

      <label style={{fontSize: '10px',color:'white'}} className='main'  htmlFor="zgoda">   <input onChange={this.handleChange} required={true} type="checkbox" id="zgoda" name="zgoda"  value="true"/>I agree to receive by electronic means, at the email address I have indicated, commercial information within the meaning of Article 10 paragraph 1 of the Act of July 18, 2002 on electronic service provision from Art Open Sp. z o.o.
      <sup>*</sup>.<span className="check"></span></label>
      <button className="darkbutton" type="submit" >
      <b>Sign me up!</b>
      </button>

      </div>
      <div style={{textAlign:'center'}}>
      <br />

      <img width="150px" src='/img/artopen-white-mono.svg' alt="Art Open advertising agency" />
      <br />
      <a className='link-white' href="/privacy-policy/">Privacy Policy</a>
      <br />
      <br />
      </div>
      </form>

    )
  }
}



export { NewsletterForm }
